<template>
  <div>
    {{ days }}D
    {{ hours }}H
    {{ minutes }}M
    {{ seconds }}S
  </div>
</template>

<script>
export default {
  data() {
    return {
      countdownDate: new Date("2025-09-20T12:00:00").getTime(),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      setInterval(() => {
        const now = new Date().getTime();
        const distance = this.countdownDate - now;

        this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      }, 1000);
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
