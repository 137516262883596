<template>
  <div id="app">
    <div class="img" id="imgMonograma">
      <img alt="monograma" src="../images/monograma.svg">
    </div>
    <div class="img" id="imgData">
      <img class="numData" alt="20" src="../images/20.svg">
      <img class="numData" alt="09" src="../images/09.svg">
      <img class="numData" alt="25" src="../images/25.svg">
    </div>
    <div id="nombres" class="textoIntro">Juan y Cecilia</div>
    <div id="lugar" class="textoIntro">SALAMANCA, ESPAÑA</div>
    <CuentaAtras id="CuentaAtras"/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

// Components
import CuentaAtras from '@/components/CuentaAtras.vue' 

export default defineComponent({
  name: 'HomeView',

  components: {
    CuentaAtras,
  },
});
</script>

<style>
@font-face {
  font-family: "Harmonais Visual - HV Muse Regular";
  src: url(~@/assets/fonts/Harmonais\ Visual\ -\ HV\ Muse\ Regular.otf) format('opentype');
}

@font-face {
  font-family: "Romantic Couple";
  src: url(~@/assets/fonts/Romantic\ Couple.ttf) format('truetype');
}
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #831418;
}

#app{
  width: 100%;
  height: 100%;
  font-family: "Harmonais Visual - HV Muse Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  background-color: #831418;

}


nav a {
  font-weight: bold;
  text-decoration: none;
  margin: 0 10px;
}

nav a.router-link-exact-active {
  color: #190202;
}


img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; 
}


#imgMonograma {
  width: 14%;
  height: auto;
  padding-top:8%;
  margin: auto;
}
img {
  max-width: 100%;
  /*height: auto;*/
  display: block;
  margin: auto;
}

/* Estilos para pantallas más grandes (computadoras) */
@media only screen and (min-width: 768px) {
  #imgData {
    width: 100%;
    height: 30%;
    padding-top: 4%;
    display: flex;
  }

  .numData{
    width: 20%;
    padding: 5%;
  }
  #imgMonograma {
    width: 8%;
    height: 6%;
    padding-top:4%;
    margin:auto;
  }

  #nombres {
    font-size: 65px;

  }
}

/* Estilos para pantallas más pequeñas (teléfonos móviles) */
@media only screen and (max-width: 767px) {
  #imgData {
    width: 100%;
    height: 60%;
    padding-top: 4%;
    display: block;
    overflow: hidden;
    align-items: center;
  }



  .numData {
    width: 45%;
    height: 33%;
    padding: 0%;
  }
}

/*#imgData {
  width: 60%;
  height: 70%;
  padding-top: 4%;
  margin: auto;
}*/




#CuentaAtras {
  /*position: fixed;
  bottom: 0;
  left: 0;*/
  width: 100%;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top:5%
}

.textoIntro {
}

#nombres {
  padding-top: 10%;
  font-size: 48px;
  font-family: 'Romantic Couple';
}
#lugar{
  font-size: 20px;
}


</style>
